import {SessionProvider, signOut, useSession} from "next-auth/react";
import {ColorSchemeProvider, LoadingOverlay, MantineProvider} from "@mantine/core";
import {useEffect, useState} from "react";
import Head from "next/head";
import dayjs from "dayjs";
import 'dayjs/locale/el';
import {RouterTransition} from "@/common/components/RouterTransition/RouterTransition";

dayjs.locale('el')
export const VEHICLE_MAPPING = {
    1: "car",
    2: "motor",
    3: "bus",
    4: "truck"
}
export default function MyApp({Component, pageProps: {session, ...pageProps}}) {

    const [colorScheme, setColorScheme] = useState('light');
    const getLayout = Component.getLayout || ((page) => page);

    const toggleColorScheme = (value) => {
        const nextColorScheme = value || (colorScheme === 'dark' ? 'light' : 'dark');
        setColorScheme(nextColorScheme);
    }

    return (
        <>
            <Head>
                <meta
                    name='viewport'
                    content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no'
                />
                <title>Σχολή Οδηγών Σωτηρίου</title>
            </Head>
            <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
                <MantineProvider withGlobalStyles withNormalizeCSS theme={{
                    components: {
                        Modal: {
                            defaultProps: {
                                centered: true,
                                closeOnClickOutside: false,
                                closeOnEscape: false,
                                size: "lg"
                            }
                        },
                        Paper: {
                            defaultProps: {
                                withBorder: true,
                                radius: "md",
                            }
                        },
                        Stack: {
                            defaultProps: {
                                spacing: "xl"
                            }
                        }
                    },
                    other: {
                        headerHeight: "70px"
                    },
                    colorScheme: colorScheme,
                    globalStyles: (theme) => ({
                        a: {
                            textDecoration: "none",
                        },
                        input: {
                            fontSize: "1rem!important"
                        },
                        'input::placeholder': {
                            fontSize: "0.875rem!important"

                        },
                        '.full-height': {
                            minHeight: `calc(100vh - ${theme.other.headerHeight})`
                        }
                    }),
                }}
                >
                    <SessionProvider session={session}>
                        <Auth>
                            <RoleLayout component={Component}>
                                <RouterTransition/>
                                <Component {...pageProps} />
                            </RoleLayout>
                        </Auth>
                    </SessionProvider>
                </MantineProvider>
            </ColorSchemeProvider>

        </>

    )
}


function Auth({children}) {
    // if `{ required: true }` is supplied, `status` can only be "loading" or "authenticated"
    const {status, data: session} = useSession();

    useEffect(() => {
        if (session?.error === "RefreshAccessTokenError") {
            signOut(); // Force sign in to hopefully resolve error
        }
    }, [session]);

    if (status === 'loading') {
        return <LoadingOverlay visible={true}/>
    }
    return children
}

function RoleLayout({component, children}) {
    const {data: session} = useSession();

    const getLayout = component.getLayout || ((page) => page);
    return getLayout(children, session?.user.role)

}
